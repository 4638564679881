import React from "react";
import insta from "../Assets/socials/insta.svg";
import twit from "../Assets/socials/twit.svg";
import link from "../Assets/socials/link.svg";
import face from "../Assets/socials/face.svg";
import tele from "../Assets/socials/tele.svg";
import you from "../Assets/socials/you.svg";
const Footer = () => {
    return (
        <div>
            <div className="container-fluid border-t-gray mt-3">
                <div className="row socials mx-auto mt-2">
                    <div className="col center">
                        <a
                            href="https://www.instagram.com/realtiacoin/?hl=en"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={insta} alt="" className="Sicon" />
                        </a>
                    </div>
                    <div className="col center">
                        <a
                            href="https://twitter.com/TiaCoin"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={twit} alt="" className="Sicon" />
                        </a>
                    </div>
                    <div className="col center">
                        <a
                            href="https://www.linkedin.com/company/tiacoin/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={link} alt="" className="Sicon" />
                        </a>
                    </div>
                    <div className="col center">
                        <a
                            href="https://www.facebook.com/TIATOKEN"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={face} alt="" className="Sicon" />
                        </a>
                    </div>
                    <div className="col center">
                        <a
                            href="https://www.youtube.com/channel/UCtWS5o3vGdnT1aNgV6ZOVug"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={you} alt="" className="Sicon" />
                        </a>
                    </div>
                    <div className="col center">
                        <a
                            href="https://t.me/realtiacoin"
                            target="_blank"
                            rel="noreferrer"
                            className="mx-auto"
                        >
                            <img src={tele} alt="" className="Sicon mx-auto" />
                        </a>
                    </div>
                </div>
                <div className="row pb-3 center content t-a-c">
                    © copyright 2023 | All rights reserved. Privacy Policy
                </div>
            </div>
        </div>
    );
};

export default Footer;
