import "./App.css";
import Landing from "./Pages/Landing";

function App() {
    return (
        <div>
            <Landing />
        </div>
    );
}

export default App;
