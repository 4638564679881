import React from "react";
import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player";

const Videos = () => {
    const videoProperties = [
        {
            id: 1,
            src: "https://youtu.be/eME4JtvwJj0",
        },
        {
            id: 2,
            src: "https://youtu.be/xvB7RcDzGsU",
        },
        {
            id: 3,
            src: "https://youtu.be/cFZfiBrKLls",
        },
        {
            id: 4,
            src: "https://youtu.be/9U4H__iUsvI",
        },
        {
            id: 5,
            src: "https://youtu.be/A1YuZXAwEFM",
        },
    ];

    return (
        <div>
            <div className="col-10 mx-auto">
                <div className="row heading py-2">
                    <div className="center">Check Out Our </div>
                    <div className="yellow center">Videos</div>
                </div>
                <div>
                    <Carousel className="carousel">
                        {videoProperties.map((videoObj) => {
                            return (
                                <Carousel.Item key={videoObj.id}>
                                    <div className="video-wrapper center">
                                        <ReactPlayer
                                            url={videoObj.src}
                                            controls={true}
                                            playing={false}
                                            pip={false}
                                            height="100%"
                                            width="100%"
                                        />
                                    </div>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default Videos;
