import React from "react";
import img5 from "../Assets/Images/img5.svg";

const Nft = (props) => {
    return (
        <div>
            <div className="col-10 mx-auto" ref={props.refe}>
                <div className="row">
                    <div className="col-lg-7">
                        <div className="row heading py-2">
                            <div>Family Centric Metaverse</div>
                            <div>
                                & NFT{" "}
                                <span className="yellow">Integration</span>
                            </div>
                        </div>
                        <div className="row content">
                            <div className="mt-3">
                                Explore a whole new world of possibilities in
                                TiaMV. Meet and socialize with other families,
                                play games, watch music concerts, comedy shows,
                                and sports live events. Trade and buy virtual
                                land, houses, and avatar NFTs all in one place.
                                Our virtual world is designed to be a fun and
                                engaging space for families to come together and
                                create memories.
                            </div>
                            <div className="mt-3 mb-3">
                                Trade and buy virtual land, houses, and avatar
                                NFTs all in one place. Our virtual world is
                                designed to be a fun and engaging space for
                                families to come together and create memories.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="center">
                            <img src={img5} alt="" className="triangle" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Nft;
