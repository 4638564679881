import React from "react";
import h1 from "../Assets/Heroes/h1.svg";
import h2 from "../Assets/Heroes/h2.svg";
import h3 from "../Assets/Heroes/h3.svg";
import h4 from "../Assets/Heroes/h4.svg";
import h5 from "../Assets/Heroes/h5.svg";
import h6 from "../Assets/Heroes/h6.svg";
const Heroes = () => {
    return (
        <div>
            <div className="col-10 mx-auto">
                <div className="heading mt-5 t-a-c">Our Family</div>
                <div className="heading yellow t-a-c">Heroes</div>
                <div className="row">
                    <div className="marquee">
                        <div className="marquee__group">
                            <img src={h1} alt="" className="heroes" />
                            <img src={h2} alt="" className="heroes" />
                            <img src={h3} alt="" className="heroes" />
                            <img src={h4} alt="" className="heroes" />
                            <img src={h5} alt="" className="heroes" />
                            <img src={h6} alt="" className="heroes" />
                        </div>

                        <div aria-hidden="true" className="marquee__group">
                            <img src={h1} alt="" className="heroes" />
                            <img src={h2} alt="" className="heroes" />
                            <img src={h3} alt="" className="heroes" />
                            <img src={h4} alt="" className="heroes" />
                            <img src={h5} alt="" className="heroes" />
                            <img src={h6} alt="" className="heroes" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Heroes;
