import React from "react";
import img1 from "../Assets/Images/img1.svg";
const Family = (props) => {
    return (
        <div>
            <div className="col-10 family mx-auto" ref={props.refe}>
                <div className="row">
                    <div className="col-lg-7">
                        <div className="row heading py-2">
                            <div>Get Your Family</div>
                            <div className="yellow">Back</div>
                        </div>
                        <div className="row content">
                            <div className="mt-3">
                                TiaMV is an extension of our family-centric
                                social media platform, TreeClan. Our virtual
                                world offers a whole new world of possibilities
                                for users to explore. From playing games, to
                                meeting distent family members, to participating
                                in interactive activities, TiaMV has something
                                for everyone.
                            </div>
                            <div className="mt-3 mb-3">
                                One of the unique features of TiaMV is its focus
                                on privacy and security. We understand the
                                importance of keeping personal information safe,
                                which is why we have implemented advanced
                                cybersecurity & Privacy solutions.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="center">
                            <img src={img1} alt="" className="triangle" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Family;
