import React, { useRef } from "react";
import logo from "../Assets/logo1.png";
import navLogo from "../Assets/nav-logo.svg";
import partners from "../Assets/partners.svg";
import Family from "../Components/Family";
import Faq from "../Components/Faq";
import Footer from "../Components/Footer";
import Heroes from "../Components/Heroes";
import Nft from "../Components/Nft";
import Roadmap from "../Components/Roadmap";
import Team from "../Components/Team";
import Token from "../Components/Token";
import Videos from "../Components/Videos";
import Why from "../Components/Why";
const Landing = () => {
    const refFamily = useRef(null);
    const refRoadmap = useRef(null);
    const refFaq = useRef(null);
    const refEvents = useRef(null);

    return (
        <div>
            <div className="bg-1">
                <nav className="navbar navbar-dark navbar-expand-lg">
                    <div className="ms-auto">
                        <button
                            className="navbar-toggler mx-4"
                            type="button ms"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarText"
                            aria-controls="navbarText"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav mx-auto mb-lg-0">
                            <li
                                className="nav-item text-white mx-4 ms-auto px-2 my-auto py-2"
                                onClick={() => {
                                    refFamily.current.scrollIntoView();
                                }}
                            >
                                MEET FAMILY
                            </li>
                            <li
                                className="nav-item text-white mx-4 ms-auto px-2 my-auto py-2"
                                onClick={() => {
                                    refEvents.current.scrollIntoView();
                                }}
                            >
                                EVENTS
                            </li>
                            <li
                                className="nav-item text-white mx-4 ms-auto px-2 my-auto py-2"
                                onClick={() => {
                                    refRoadmap.current.scrollIntoView();
                                }}
                            >
                                ROADMAP
                            </li>
                            <li className="nav-item text-white mx-4 ms-auto px-2 my-auto py-2">
                                <a
                                    href="https://drive.google.com/file/d/1So9Bg52xmMaVdDh4yYverK8bSRA4x0wx/view?usp=sharing"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-white"
                                >
                                    WHITEPAPER
                                </a>
                            </li>
                            <li
                                className="nav-item text-white mx-4 ms-auto px-2 my-auto py-2"
                                onClick={() => {
                                    refFaq.current.scrollIntoView();
                                }}
                            >
                                FAQ
                            </li>
                            <li className="nav-item text-white mx-4 ms-auto px-2 my-auto py-2 dropdown">
                                <div
                                    className="nav-link dropdown-toggle text-white"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Our Projects
                                </div>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="navbarDropdown"
                                >
                                    <li>
                                        <a
                                            href="https://fundtia.com/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="dropdown-item"
                                        >
                                            FundTia
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="http://tiacoin.io/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="dropdown-item"
                                        >
                                            Tiacoin
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://tiaxchange.com/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="dropdown-item"
                                        >
                                            TiaXchange
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://treeclan.com/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="dropdown-item"
                                        >
                                            TreeClan
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://tiasecure.com/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="dropdown-item"
                                        >
                                            TiaSecure
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item text-white mx-4 ms-auto px-2 my-auto">
                                <img src={navLogo} alt="" className="nav-img" />
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="empty-box"></div>
                <div className="center">
                    <div className="box">
                        <div className="row justify-content-evenly">
                            <button className="button-5">Live</button>
                            <button className="button-5">Work</button>
                            <button className="button-5">Play</button>
                            <button className="button-5">Meet</button>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row center">
                        <img src={logo} alt="" className="logo" />
                    </div>
                </div>
                <div className="page-1-text mx-auto">
                    Welcome to TiaMV, the revolutionary new virtual world for
                    families and children! Our platform prioritizes user privacy
                    and security, providing a safe and secure environment for
                    kids to explore and interact in. With TiaMV, parents can
                    feel confident that their children are in a safe and
                    appropriate space.
                </div>
                <div className="center">
                    <div className="row justify-content-evenly page-1-btn-width">
                        <div className="page-1-btn">
                            <div className="reverse-skew">Get NFT</div>
                        </div>
                        <div className="page-1-btn">
                            <div className="reverse-skew">Buy TiaCoin</div>
                        </div>
                        <div className="page-1-btn">
                            <div className="reverse-skew">
                                Telegram/Whatsapp
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={partners} className="partners" alt="" />
                </div>
            </div>
            <Family refe={refFamily} />
            <Videos />
            <Token />
            <Heroes />
            <Nft refe={refEvents} />
            <Why />
            <Roadmap refe={refRoadmap} />
            <Team />
            <Faq refe={refFaq} />
            <Footer />
        </div>
    );
};

export default Landing;
