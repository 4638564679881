import React from "react";
import c1 from "../Assets/Cards/c1.svg";
import c2 from "../Assets/Cards/c2.svg";
import c3 from "../Assets/Cards/c3.svg";

const Why = () => {
    return (
        <div>
            <div className="col-10 mx-auto">
                <div className="row">
                    <div className="heading t-a-c mt-3">Why</div>
                    <div className="heading t-a-c yellow">TiaMV & TiaCoin</div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-4 p-2 center">
                        <img src={c1} alt="" className="img-fluid" />
                    </div>
                    <div className="col-lg-4 p-2 center">
                        <img src={c2} alt="" className="img-fluid" />
                    </div>
                    <div className="col-lg-4 p-2 center">
                        <img src={c3} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Why;
