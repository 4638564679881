import React from "react";

const Faq = (props) => {
    const Accordion = ({ title, children }) => {
        const [isOpen, setOpen] = React.useState(false);
        return (
            <div className="accordion-wrapper">
                <div
                    className={`accordion-title${isOpen ? "-1 open" : ""}`}
                    onClick={() => setOpen(!isOpen)}
                >
                    {title}
                </div>
                <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                    <div className="accordion-content">{children}</div>
                </div>
            </div>
        );
    };
    return (
        <div>
            <div className="col-10 mx-auto" ref={props.refe}>
                <div className="heading my-3 t-a-c">
                    Frequently Asked Questions
                </div>
                <div className="content t-a-c mb-4">
                    Below we have provided our summarized frequently asked
                    Questions. If you have additional Questions, Concerns,
                    improvement Proposals or you would like to Cooperate with
                    us, please get in touch with us by using the Contact Form
                    below
                </div>
                <div className="row py-4">
                    <div className="wrapper">
                        <Accordion title="What is TiaMV?">
                            TiaMV is a family-centric Metaverse platform that
                            prioritizes user privacy and security, and provides
                            a virtual world for families and children to explore
                            and interact in. It is designed to be safe and
                            secure, with no space for abuse or violent games.
                        </Accordion>
                        <Accordion title="How is TiaMV different from other platforms?">
                            TiaMV places a strong emphasis on user privacy and
                            security, and is specifically designed to be a safe
                            and appropriate environment for children. It also
                            features a virtual world for families and children
                            to explore and interact in, making it unique
                            compared to other platforms.
                        </Accordion>
                        <Accordion title="What is the TiaCoin and how does it relate to TiaMV?">
                            TiaCoin is the official cryptocurrency of the TiaMV
                            platform, and is used for transactions within the
                            platform, such as buying and selling in-game items,
                            virtual land and houses, and avatar NFTs.
                        </Accordion>
                        <Accordion title="What kind of games will be available on TiaMV?">
                            TiaMV will feature a high-end PC and metaverse games
                            that allows players to purchase NFT items directly
                            in the game using TiaCoin tokens. It will also have
                            a marketplace for buying and selling digital arts
                            and in-game skins, as well as opportunities to earn
                            passive income through staking and in-game rewards.
                        </Accordion>
                        <Accordion title="Is TiaMV a secure platform?">
                            es, TiaMV places a strong emphasis on user privacy
                            and security, and has implemented strict security
                            measures to protect all users' personal information.
                            It is also designed to be a safe and appropriate
                            environment for children, with no space for abuse or
                            violence.
                        </Accordion>
                        <Accordion title="How can I purchase TiaCoin?">
                            TiaCoin can be purchased through cryptocurrency
                            exchanges that support TiaCoin trading. You can also
                            purchase TiaCoin through TiaXchange, which is the
                            official exchange platform for TiaCoin.
                        </Accordion>
                        <Accordion title="How can I earn money in TiaMV?">
                            You can use TiaCoin directly in the game and earn
                            passive income. While playing the game, you can also
                            earn money and in-game rewards are distributed to
                            players. TiaMV also has a Play2Earn mechanism, where
                            players can participate in tournaments and win
                            TiaCoin tokens.
                        </Accordion>
                        <Accordion title="When TiaMV will be launched?">
                            TiaMV Beta metaverse expected to be released to the
                            public by the end of 2023.
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;
