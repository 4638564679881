import React from "react";
import teamImg from "../Assets/Team/team.svg";
import teamImg2 from "../Assets/Team/team2.svg";

const Team = () => {
    return (
        <div>
            <div className="col-10 mx-auto mt-2">
                <div className="row">
                    <div className="heading t-a-c">
                        Our <span className="yellow">Team</span>
                    </div>
                </div>
                <div className="row center my-5">
                    <div className="col-lg-6">
                        <img src={teamImg} alt="" className="img-fluid my-2" />
                    </div>
                    <div className="col-lg-6">
                        <img src={teamImg2} alt="" className="img-fluid my-2" />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="heading t-a-c">
                        You can participate in our funding round
                    </div>
                    <div className="heading t-a-c yellow">
                        get unbelievable bonus product
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="heading t-a-c">
                        Visit
                    </div>
                    <div className="heading t-a-c yellow">
                        <a href="https://fundtia.com/" target="_blank" rel="noreferrer"> www.fundtia.com</a>
                    </div>
                    <div className="my-2 heading t-a-c">
                        or
                    </div>
                </div>
                <div className="center">
                    <div className="row justify-content-evenly page-1-btn-width">
                        <div className="page-1-btn">
                            <div className="reverse-skew">Click Here</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Team;
