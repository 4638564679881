import React from "react";
import img2 from "../Assets/Images/img2.svg";
import img3 from "../Assets/Images/img3.svg";
import image1 from "../Assets/image1.jpg";
import image2 from "../Assets/image2.jpg";
const Token = () => {
    return (
        <div>
            <div className="col-10 mx-auto">
                <div className="heading mt-5 t-a-c">TiaCoin Token Will</div>
                <div className="heading yellow t-a-c">Take You There</div>
                <div className="content my-3 t-a-c">
                    How TiaCoin Token are distributed
                </div>
                <div className="row">
                    <div className="col-md-5 center">

                        <img src={image2} className="table my-4" alt="" />

                    </div>

                    <div className="col-md-7 center">
                        <img src={img3} className="table my-4" alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Token;
