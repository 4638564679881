import React from "react";
import roadmap from "../Assets/roadmap.svg";
const Roadmap = (props) => {
    return (
        <div>
            <div className="col-10 mx-auto">
                <div className="row mt-3">
                    <div className="heading mt-5" ref={props.refe}>
                        TiaMV <span className="yellow">Roadmap</span>
                    </div>
                </div>
                <div className="row center mt-5">
                    <div className="col-lg-9 center">
                        <img src={roadmap} alt="" className="table" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roadmap;
